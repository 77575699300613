// @flow
// websocket连接通道的评测和选择
import { getTokenData } from '~/utils/urlHelper';
import queryString from 'query-string';
// $flow-disable-line
const globalConfig = require('configPath');

type Url = {
  name: string, // 标识性字段
  socketUrl: string, // websocket连接用
  testUrl?: string, // 测速用
  cost: number, // 连接测试的时间花费，其倒数表达了快速程度
  lastTestTime: number, // 最近测试时间
  lastUsedTime: number // 最近使用时间
};

// websocket成功连接后：
// 立即对所有连接进行一次测速，此后每半个小时测试一次，测试结果存储于localStorage

export class UrlManager {
  records: Url[]; // url数据记录
  counter: TimeoutID;

  constructor() {
    const { hostname, search } = location;
    const params = queryString.parse(search);
    const isAbroad = (params.abroad && params.abroad === '1') || hostname.includes('weex.com');
    console.log('isAbroad', isAbroad)
    // 當官網域名是國內域名時需要看國內線路來組合wss
    let sUrl = `wss://contract.${isAbroad ? 'weex.com' : localStorage.getItem('wss:url')}/websocket`;
    const query = `uuid=${new Date().getTime() * 740 + ''}${this.socketCompress ? '&compress=true' : ''}&data=${getTokenData(sUrl.replace('wss://', ''))}`
    sUrl += (sUrl.indexOf('?') > -1 ? '&' : '?') + query;
    // if (globalConfig.env && globalConfig.env === 'online') {
    //   const wssUrl = localStorage.getItem('wss:url');
    //   if (wssUrl) sUrl = sUrl.replace('weex.com', wssUrl);
    // }
    // sUrl =
    //   sUrl +
    //   (sUrl.indexOf('?') > -1 ? '&' : '?') +
    //   'uuid=' +
    //   (new Date().getTime() * 740 + '') +
    //   (this.socketCompress ? '&compress=true' : '') +
    //   '&data=' +
    //   getBase64WSQueryObj(sUrl.replace('wss://', ''));
    localStorage.setItem('weex::socket::score', []);
    let socketRecords = JSON.parse(localStorage.getItem('weex::socket::score') || '[]');
    let baseBackupSocketUrl = [];
    // 现在beta和online都认为是线上，beta更针对国外用户
    let host = location.host.replace('www.', '');
    let special = {
      name: "new-prod-3",
      //socketUrl: "wss://contract-websocket.#/websocket".replace("#", host)
      //socketUrl: sUrl //PRD use
      socketUrl: 'wss://stg-contract-websocket.weex.tech/websocket' //prd use
    };
    if (globalConfig.env === 'beta') {
      special.socketUrl = special.socketUrl + '?beta=true';
    }
    if (isAbroad) {
      // weex.com针对国外用户
      baseBackupSocketUrl.push(special);
      baseBackupSocketUrl.push(...globalConfig.backupSocketUrl);
    } else {
      baseBackupSocketUrl.push(...globalConfig.backupSocketUrl);
    }

    // 注意：这里是以项目配置为准，保证能够正确弃用缓存数据
    this.records = merge(baseBackupSocketUrl, socketRecords);
  }

  /**
   * 保存数据
   */
  saveRecord() {
    localStorage.setItem('weex::socket::score', JSON.stringify(this.records));
  }

  /**
   * 每次调用都将返回最好的url
   * @return {*}
   */
  urlProvider = (compress: boolean = false) => {
    this.evaluateTask();
    // 每次建立websocket连接时，都会使用该方法返回的字符串作为目标
    let fast =
      minBy(
        this.records,
        i => Number(i.cost),
        i => Number(i.lastUsedTime)
      ) || this.records[0];
    fast.lastUsedTime = Date.now();
    let targetUrl = fast.socketUrl;
    targetUrl += `?${compress ? 'compress=true&' : ''}data=${getTokenData(
      targetUrl.replace('wss://', '')
    )}`;
    return targetUrl;
  };

  // 每半小时执行一次测速
  evaluateTask = () => {
    if (this.counter) {
      clearTimeout(this.counter);
    }
    let task = () => {
      this.evaluate();

      // 每半小时执行一次
      this.counter = setTimeout(task, 30 * 60 * 1000);
    };
    task();
  };

  evaluate() {
    // 对每个链接进行测试
    let allRequest = this.records.map(socket => testUrl(socket));

    // 所有探测都结束
    Promise.all(allRequest).then(() => {
      this.saveRecord();
    });
  }

  stop() {
    if (this.counter) {
      clearTimeout(this.counter);
    }
  }
}

/**
 * 建议的发送请求
 * @param url
 */
function testUrl(url: Url) {
  return new Promise(resolve => {
    let testBegin = Date.now();
    let testConnection = new WebSocket(
      url.socketUrl + (url.socketUrl.indexOf('?') > -1 ? '&' : '?') + 'check=1'
    );
    let protectTimer = null;
    let messageFlag = false;
    let connectTimeoutFlag = false;

    // 4s连不上直接去世，两个通道也就8s
    let connectTimer = setTimeout(() => {
      connectTimeoutFlag = true;
      url.lastTestTime = Date.now();
      url.cost = 99999;
      resolve();
    }, 4 * 1000);

    testConnection.onopen = function() {
      if (connectTimeoutFlag) {
        // 如果超时后连接建立，直接关闭连接
        testConnection.close();
        return;
      }
      clearTimeout(connectTimer);
      protectTimer = setTimeout(() => {
        if (testConnection && testConnection.readyState === testConnection.OPEN) {
          // 10s后如果测试连接还没有主动断掉
          // 如果是接到消息了，那断掉就行了
          // 如果没有接到消息，差评并断掉
          // 直接交给onclose事件去处理，这里只是确保能够关掉连接
          testConnection.close();
        }
      }, 10 * 1000);
    };

    testConnection.onerror = function() {
      if (connectTimeoutFlag) {
        return;
      }
      // 如果有报错，直接差评，结束这次测试
      url.cost = 99999;
      url.lastTestTime = Date.now();
      if (protectTimer) {
        clearTimeout(protectTimer);
      }
      if (connectTimer) {
        clearTimeout(connectTimer);
      }
      resolve();
    };

    testConnection.onmessage = function() {
      if (connectTimeoutFlag) {
        return;
      }
      // 正常流程是到收到消息为一次完整的统计，
      // 统计完等服务器主动关闭，如果服务器不主动关闭我就直接差评，谁让服务器说他会自己主动断的
      let testEnd = Date.now();
      url.lastTestTime = testEnd;
      url.cost = testEnd - testBegin;
      messageFlag = true;
      resolve();
    };

    testConnection.onclose = function() {
      if (connectTimeoutFlag) {
        return;
      }
      if (!messageFlag) {
        // 2.没有发送任何数据就给我断了，那就直接差评
        url.lastTestTime = Date.now();
        url.cost = 99999;
        resolve();
      }
      // 1.测试完成服务器主动关闭
    };
  });
}

/**
 * 取arr的最小
 * @param arr
 * @param iteratee 第一排序字段
 * @param iteratee2 第二排序字段
 */
function minBy(arr, iteratee, iteratee2) {
  if (!arr || arr.length < 0) {
    return undefined;
  }

  let min = arr[0];
  arr.forEach(elm => {
    let key1 = iteratee(elm),
      key2 = iteratee2(elm);
    if (key1 > 0) {
      if (key1 < iteratee(min)) {
        min = elm;
      } else if (key1 === iteratee(min)) {
        if (key2 < iteratee2(min)) {
          min = elm;
        }
      }
    }
  });
  return min;
}

/**
 * 基于configs生成一个新的计分板
 * @param socketConfigs 配置
 * @param socketRecords 缓存数据
 */
function merge(socketConfigs, socketRecords: Url[]) {
  let newRecords: Url[] = [];
  for (let config of socketConfigs) {
    let record = socketRecords.find(i => i.name === config.name);
    if (record) {
      newRecords.push(record);
    } else {
      newRecords.push({
        ...config,
        cost: -1,
        lastTestTime: -1,
        lastUsedTime: -1
      });
    }
  }
  return newRecords;
}
