// @flow
// 不再覆盖原本的事件

export const TIMEOUT_ERROR = "TIMEOUT";
export const CONNECTION_ERROR = "SYSTEM";


export class WrappedErrorEvent {
  event: Event | null; // 包裹原始的事件
  message: string; // 错误信息
  target: any; // 包裹发生事件的实例

  constructor(event: Event | null, message: string, target: any) {
    this.event = event;
    this.message = message;
    this.target = target;
  }
}

export class WrappedCloseEvent {
  code: number; // 对应CloseEvent的code
  reason: string; // 对应CloseEvent的reason
  wasClean: boolean; // 对应CloseEvent的wasClean
  event: CloseEvent | null; // 包裹原始的关闭事件
  target: any; // 事件目标

  constructor(event: CloseEvent | null, code?: number, reason?: string) {
    this.event = event;
    this.code = event ? event.code : (code || 1000);
    this.reason = event ? event.reason : (reason || "");
    this.wasClean = event ? event.wasClean : true;
    this.target = null;
  }
}

/**
 * 链接错误码
 * @type {{E1007: string}}
 */
export const CONNECTION_ERROR_CODE = {
  E1007: 1007, // 客户端时间戳与服务端时间戳不匹配（相差10s以上）
};
